<template>
  <b-modal
    id="form-url-modal"
    footer-class="d-flex justify-content-center"
    hide-header
  >
    <h5 class="mb-2" v-html="title" style="text-align: center"></h5>
    <h5 class="mb-2">填寫網址</h5>
    <b-textarea
      id="form-url"
      v-b-tooltip.click="'已複製'"
      v-model="formUrl"
      @click="copyFormUrl('#form-url')"
    ></b-textarea>
    <template v-if="!!recordUrl">
      <h5 class="my-2">填寫紀錄</h5>
      <b-textarea
        id="record-url"
        v-b-tooltip.click="'已複製'"
        v-model="recordUrl"
        @click="copyFormUrl('#record-url')"
      ></b-textarea>
    </template>
    <template #modal-footer>
      <b-button
        size="lg"
        variant="danger"
        @click="$bvModal.hide('form-url-modal')"
        >關閉</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import generateLiffUrl from "@/mixins/Dashboard/liffUrlHelper";

export default {
  mixins: [generateLiffUrl],
  data() {
    return {
      formUrl: null,
      recordUrl: null,
      title    : null,
    };
  },
  methods: {
    showFormUrl(bookingPreset) {
      this.title = bookingPreset.name;
      this.formUrl = this.generateLiffUrl(`booking/form/${bookingPreset.id}`);
      this.recordUrl = this.generateLiffUrl(`booking/${bookingPreset.id}`);
      this.$bvModal.show("form-url-modal");
    },
    copyFormUrl(id) {
      const inputText = document.querySelector(id);
      inputText.select();
      document.execCommand("copy");
    },
  },
};
</script>
